@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  overflow: auto;
  font-family: "Source Sans Pro", sans-serif;
}

body {
  margin: 0;;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.link {
  cursor: pointer
}

.link:hover {
  color: #6a92ed;
}

.rc-virtual-list-scrollbar {
  background: rgb(224, 224, 224) !important;
}

.rc-virtual-list-scrollbar-thumb {
  background: rgb(136, 136, 136) !important;
}